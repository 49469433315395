@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;700&display=swap");

html,
body {
    padding: 0;
    margin: 0;
    font-family:
        Roboto,
        Oxygen,
        Ubuntu,
        Cantarell,
        Fira Sans,
        Droid Sans,
        Helvetica Neue,
        sans-serif;
}

* {
    box-sizing: border-box;
    scroll-behavior: smooth;
}

@layer base {
    body {
        @apply text-sm text-holon-blue-900;
    }

    h1 {
        @apply text-3xl lg:text-5xl font-bold;
    }

    h2 {
        @apply text-2xl lg:text-3xl font-semibold;
    }

    h3,
    h4,
    h5 {
        @apply text-2xl font-semibold;
    }

    p,
    li {
        @apply text-base lg:leading-7;
    }

    .stretched-link::after {
        content: "";
        @apply z-10 absolute inset-0 bg-transparent pointer-events-auto;
    }
}

.card__bg-gold {
    @apply bg-holon-gold-200;
}

.card__bg-blue {
    @apply bg-holon-blue-100;
}

.card__bg-gray {
    @apply bg-holon-gray-100;
}

.card__bg-purple {
    @apply bg-holon-purple-100;
}

.card__bg-pink {
    @apply bg-holon-pink-100;
}
.card__bg-orange {
    @apply bg-holon-orange-100;
}

.block__bg-gray {
    @apply bg-holon-gray-100;
}

.block__bg-purple {
    @apply bg-holon-purple-100;
}

input::-moz {
}
.wiki-context-menu .wiki-context-menu-link {
    display: inherit;
}
.wiki-context-menu .wiki-context-menu-link:before {
    content: "› ";
}
.wiki-breadcrumbs a {
    text-decoration: underline;
}

.storyline__row p {
    font-size: 16px;
    margin-bottom: 1.5rem;
}

.floorplan {
    height: 80vh;
    width: 800px;
    padding-top: 250px;
    margin: 0 auto;
}
.floorplan__tile {
    position: relative;
    overflow: visible;
    display: inline-block;
    aspect-ratio: 4.45 / 1;
}
.floorplan__img {
    position: absolute;
    bottom: 0;
    width: 100%;
}
[data-size="2"] .floorplan__tile {
    aspect-ratio: 4.23 / 1;
}
[data-size="3"] .floorplan__tile {
    aspect-ratio: 4.3 / 1;
}

[data-size="2"] div:nth-child(1),
[data-size="2"] div:nth-child(4) {
    margin-left: 25%;
}

[data-size="3"] div:nth-child(1),
[data-size="3"] div:nth-child(9) {
    margin-left: calc(100% / 3);
}
[data-size="3"] div:nth-child(2),
[data-size="3"] div:nth-child(7) {
    margin-left: calc(100% / 6);
}

[data-size="4"] div:nth-child(1),
[data-size="4"] div:nth-child(16) {
    margin-left: calc(37.5%);
}
[data-size="4"] div:nth-child(2),
[data-size="4"] div:nth-child(14) {
    margin-left: calc(100% / 4);
}
[data-size="4"] div:nth-child(4),
[data-size="4"] div:nth-child(11) {
    margin-left: calc(12.5%);
}
.defaultBlockPadding {
    @apply py-8 px-10 lg:px-16 lg:py-12;
}

.holonContentContainer {
    max-width: 1600px;
    margin: 0 auto;
}
.extra_bg {
    position: absolute;
    top: 0;
    right: 100%;
    width: 100vw;
    height: 100%;
}
.inverseColumns .extra_bg {
    left: 100%;
    right: auto;
}

.labelInputForm {
    @apply block text-gray-700 text-lg font-bold mb-0.5 mt-8;
}

.inputForm {
    @apply shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight;
}

.buttonDark {
    @apply border-holon-blue-900  text-white bg-holon-blue-900 hover:bg-holon-blue-500 flex flex-row justify-center items-center relative rounded border-2 px-4 py-3 mb-4 min-w-[8rem] text-center font-medium leading-5 transition enabled:active:translate-x-holon-bh-x disabled:opacity-50;
}

.buttonLight {
    @apply border-holon-blue-900  text-holon-blue-900 bg-white hover:bg-holon-blue-500 hover:text-white flex flex-row justify-center items-center relative rounded border-2 px-4 py-3 mb-4 min-w-[8rem] text-center font-medium leading-5 transition enabled:active:translate-x-holon-bh-x disabled:opacity-50;
}
